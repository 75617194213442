import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthSlice from './reducers/AuthSlice';
import ProductSlice from './reducers/ProductSlice';
import CommonSlice from './reducers/CommonSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['AuthSlice', 'CommonSlice', 'ProductSlice']
};

const reducers = combineReducers({
    AuthSlice,
    ProductSlice,
    CommonSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
});

const persistor = persistStore(store);
export { store, persistor };