import { useState } from 'react';
import { Button, Form, Modal, Input } from 'antd';
import { getFetchApi } from '../redux/action';
import { useDispatch } from 'react-redux';
import { authData } from '../redux/reducers/AuthSlice';
import closeImg from '../assets/images/close.png';
import Toast from '../utils/Toast';
const { TextArea } = Input;

const DeleteAccount = ({ deleteAccModal, setDeleteAccModal, userData }) => {
    const [formFields] = Form.useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleDeleteAccount = () => {
        setLoading(true)
        const apiCallBack = (res) => {
            setLoading(false)
            if (res?.data?.status) {
                Toast('s', `${res?.data?.message}`);
                setDeleteAccModal(false)
                dispatch(authData());
                localStorage.clear();
                formFields.resetFields();
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = {
            _id: userData?._id,
            "status": "2"
        }
        let url = `user/account_update`
        dispatch(getFetchApi({ url, data, apiCallBack }))
    }

    return (
        <>
            <Modal open={deleteAccModal} footer='' closable={false} width="40%">
                <div className="p-3">
                    <div className="text-end">
                        <img src={closeImg} alt="close" width='6%' onClick={() => setDeleteAccModal(false)} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="row">
                        <div className="col-lg-9 mx-auto modal_font">
                            <h5 className='medium_font fw-bold text-center'>DELETE ACCOUNT</h5>
                            <dt className='mb-4'>We hate to see you go. Please leave a brief description explaining why you want to delete your account. Any feedback on how we can improve the application would be greatly appreciated!</dt>
                            <Form form={formFields} onFinish={handleDeleteAccount} autoComplete="off" >
                                <Form.Item
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your message!',
                                        },
                                    ]}
                                >
                                    <TextArea type='email' className='common_input profile_input' placeholder='I am deleting this account because…' style={{ minHeight: "200px" }} />
                                </Form.Item>

                                <Form.Item className='text-center mt-3 mt-md-4 mt-lg-5'>
                                    <Button loading={loading} className='common_btn common_btn_width' type="ghost" htmlType="submit">DELETE ACCOUNT</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DeleteAccount;
