import { DotLoader } from "react-spinners";

const Loader = () => {
    return (
        <>
            <div className='loader_div'>
                <DotLoader size={100} color="#000087" />
            </div>
        </>
    )
}

export default Loader;