import { apiCall } from "../../api";
import { baseUrl } from "../../constant/url";
import { store } from "../store";
import { cartCountData } from "../reducers/ProductSlice";


export const getFetchApi = ({ url, method = 'POST', data, hasFileUpload = false, apiCallBack }) => async (dispatch) => {

    const token = store.getState()?.AuthSlice?.auth_data?.data?.token
    const csrfToken = store.getState()?.AuthSlice?.auth_data?.data?.csrfToken;

    let config = {
        method: method,
        url: `${baseUrl}${url}`,
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
            "Content-Type": hasFileUpload ? "multipart/form-data" : "application/json",
            'x-csrf-token': csrfToken,
            from: 'server'
            // from: 'local'
        }
    }

    if (data) {
        config.data = data
    }

    let response = await apiCall(config, dispatch);
    try {
        apiCallBack(response);
    } catch (error) {
        apiCallBack(error);
    }
}

export const getCartCount = () => async (dispatch) => {
    const token = store.getState()?.AuthSlice?.auth_data?.data?.token
    const csrfToken = store.getState()?.AuthSlice?.auth_data?.data?.csrfToken;

    let config = {
        method: "POST",
        url: `${baseUrl}user/get_cart_count`,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'x-csrf-token': csrfToken,
            from: 'server'
            // from: 'local'
        }
    }

    let response = await apiCall(config, dispatch);
    try {
        dispatch(cartCountData(response?.data?.data))
    } catch (error) {
        console.log("Error", error)
    }
}
