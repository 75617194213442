const StrongPassword = (passwordVal) => {
    const strongPasswordPattern = /^(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{6,}$/;
    return new Promise((resolve, reject) => {
        if (!strongPasswordPattern.test(passwordVal)) {
            reject(`Password must be at least 6 characters long and contain at least one special character`)
        } else {
            resolve()
        }
    })
};

export default StrongPassword;