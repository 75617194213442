import { Link } from 'react-router-dom';
import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className="not_found_div">
                <div className="text-center shop_text">
                    <h1 className='oops_text'>Oops !</h1>
                    <h5 className='auth_sub_title fw-bold'>404 - PAGE NOT FOUND</h5>
                    <h6 className='auth_sub_title'>The page you are looking for might have been removed <br /> had its name changed or temporarily unavailable</h6>
                    <Link to='/'><button className='common_btn px-5 mt-4' style={{ borderRadius: "50px" }}>GO TO HOMEPAGE</button></Link>
                </div>
            </div>
        </>
    )
}

export default NotFound;
