import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFetchApi } from "../redux/action";
import { fileUrl } from "../constant/url";
import { userDetail } from "../redux/reducers/AuthSlice";
import closeImg from '../assets/images/close.png';
import addProfile from '../assets/images/addprofile.png';
import userImg from '../assets/images/user.png';
import Toast from "../utils/Toast";

const Profile = ({ profileModal, setProfileModal }) => {
    const dispatch = useDispatch();
    const [editForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [profilePic, setProfilePic] = useState([]);
    const userData = useSelector(state => state?.AuthSlice?.user_detail);


    const handleChange = ({ fileList: newFileList }) => {
        console.log("newFileList dubey", fileList?.[0]?.originFileObj)
        let data = new FormData();
        setFileList(newFileList);
        if (newFileList?.length > 0) {
            if (newFileList?.[0]?.size >= 10 * 1024 * 1024) {
                Toast('e', 'Image should be less than 10MB')
                return;
            } else {
                data.append("user_media", newFileList?.[0]?.originFileObj)
            }
            const apiCallBack = (res) => {
                if (res?.data?.status) {
                    setProfilePic(res?.data?.data?.[0])
                } else {
                    Toast('e', res?.message)
                }
            }
            let url = 'user/upload_media';
            let hasFileUpload = true;
            dispatch(getFetchApi({ url, data, hasFileUpload, apiCallBack }))
        }
    };

    const handleUpdateProfile = (value) => {
        setLoading(true)
        const apiCallBack = (res) => {
            setLoading(false)
            if (res?.data?.status) {
                Toast('s', `${res?.data?.message}`);
                dispatch(userDetail(res?.data?.data))
                setEditMode(false)
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = {
            full_name: value?.full_name,
            phone_no: value?.phone_no,
        }
        if (profilePic) {
            data.profile_pic = profilePic
        }
        let url = `user/update_profile`
        dispatch(getFetchApi({ url, data, apiCallBack }))
    };

    const handleCompleteProfile = (value) => {
        setLoading(true);
        const apiCallBack = (res) => {
            setLoading(false);
            if (res?.data?.status) {
                setProfileModal(false);
                dispatch(userDetail(res?.data?.data));
                Toast('s', `${res?.data?.message}`);
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = {
            full_name: value?.full_name,
            phone_no: value?.phone_no,
            email: value?.email,
        }
        let url = `user/update_profile`;
        dispatch(getFetchApi({ url, data, apiCallBack }));
    };

    useEffect(() => {
        if (userData) {
            editForm.setFieldsValue({
                full_name: userData?.full_name,
                email: userData?.email,
                phone_no: userData?.phone_no,
            })
        }
    }, [userData]);

    return (
        <>
            {userData?.email == "" || userData?.full_name == "" || userData?.phone_no == "" ?
                <Modal open={profileModal} footer='' closable={false} width="40%">
                    <div className="p-3">
                        <div className="row">
                            <div className="col-lg-9 mx-auto shop_text">
                                <h5 className='medium_font mb-3 text-center'>COMPLETE YOUR PROFILE</h5>
                                <Form form={editForm} onFinish={handleCompleteProfile} autoComplete="off" >
                                    <Form.Item className='d-flex justify-content-center'>
                                        {userData?.full_name && <h6 className='medium_font text-center mt-2'>{userData?.full_name}</h6>}
                                    </Form.Item>

                                    <Form.Item
                                        name="full_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your your name!',
                                            },
                                        ]}
                                    >
                                        <Input disabled={userData?.full_name !== ''} className='common_input profile_input' placeholder='Full Name' />
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email address!',
                                            },
                                        ]}
                                    >
                                        <Input disabled={userData?.email !== ''} type='email' className='common_input profile_input' placeholder='Email Address' />
                                    </Form.Item>

                                    <Form.Item
                                        name="phone_no"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your phone number!',
                                            },
                                        ]}
                                    >
                                        <Input maxLength={10} disabled={userData?.phone_no !== ''} className='common_input profile_input' placeholder='Phone Number' />
                                    </Form.Item>

                                    <Form.Item className='text-center mt-5 mb-4'>
                                        <Button loading={loading} className='common_btn common_btn_width' type="ghost" htmlType="submit">SAVE</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
                :
                <Modal open={profileModal} footer='' closable={false} width="40%">
                    <div className="p-3">
                        <div className="text-end">
                            <img src={closeImg} alt="close" width='6%' onClick={() => { setProfileModal(false); setEditMode(false) }} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="row">
                            <div className="col-lg-9 mx-auto">
                                <Form form={editForm} onFinish={handleUpdateProfile} autoComplete="off" >
                                    <Form.Item className='d-flex justify-content-center'>
                                        {editMode ?
                                            <Upload
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-circle"
                                                fileList={fileList}
                                                onChange={handleChange}
                                                // beforeUpload={handleChange}
                                                accept={"image/*"}
                                                showUploadList={{
                                                    showPreviewIcon: false,
                                                    showDownloadIcon: false,
                                                    showRemoveIcon: true,
                                                }}
                                            >
                                                {fileList.length >= 1 ? null : <img src={addProfile} width='100%' alt="profile" />}
                                            </Upload>
                                            :
                                            <div className="text-center">
                                                <img src={userData?.profile_pic !== "" ? fileUrl + userData?.profile_pic : userImg} alt="profile pic" className="profile_img" />
                                            </div>
                                        }
                                        <h6 className='medium_font text-center mt-2'>{userData?.full_name}</h6>
                                    </Form.Item>

                                    <Form.Item
                                        name="full_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your your name!',
                                            },
                                        ]}
                                    >
                                        <Input disabled={!editMode} className='common_input profile_input' placeholder='Full Name' />
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email address!',
                                            },
                                        ]}
                                    >
                                        <Input type='email' disabled className='common_input profile_input' placeholder='Email Address' />
                                    </Form.Item>

                                    <Form.Item
                                        name="phone_no"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your phone number!',
                                            },
                                        ]}
                                    >
                                        <Input maxLength={10} disabled={!editMode} className='common_input profile_input' placeholder='Phone Number' />
                                    </Form.Item>

                                    {!editMode ?
                                        <div className='text-center my-5 mb-4'>
                                            <Button className='common_btn common_btn_width' type="ghost" onClick={() => setEditMode(true)}>EDIT PROFILE</Button>
                                        </div>
                                        :
                                        <Form.Item className='text-center mt-5 mb-4'>
                                            <Button loading={loading} className='common_btn common_btn_width' type="ghost" htmlType="submit">SAVE</Button>
                                        </Form.Item>
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default Profile;
