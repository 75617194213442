import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    all_products: {},
    cart_list: {},
    cart_count: {},
}

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        allProductData: (state, action) => {
            state.all_products = action.payload
        },
        cartListData: (state, action) => {
            state.cart_list = action.payload
        },
        cartCountData: (state, action) => {
            state.cart_count = action.payload
        },
    }
});

export const { allProductData, cartListData, cartCountData } = productSlice.actions;
export default productSlice.reducer;