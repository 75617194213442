import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    auth_data: {},
    user_detail: {},
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authData: (state, action) => {
            state.auth_data = action.payload
        },
        userDetail: (state, action) => {
            state.user_detail = action.payload
        },
    }
})

export const { authData, userDetail } = authSlice.actions
export default authSlice.reducer