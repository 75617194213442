import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, Modal } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { getFetchApi } from '../redux/action';
import closeImg from '../assets/images/close.png';
import Toast from '../utils/Toast';
import StrongPassword from '../utils/StrongPassword';

const ChangePassword = ({ passwordModal, setPasswordModal }) => {
    const dispatch = useDispatch();
    const [passForm] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleChangePassword = async (formVal) => {
        if (formVal?.new_password === formVal?.old_password) {
            return Toast('e', `New password is different from the old password`);
        }
        if (formVal?.new_password !== formVal?.c_password) {
            return Toast('e', `Both passwords must be the same`);
        }
        try {
            await StrongPassword(formVal?.new_password);
            setLoading(true);
            const apiCallBack = (res) => {
                setLoading(false);
                if (res?.data?.status) {
                    setPasswordModal(false);
                    passForm.resetFields();
                    Toast('s', `${res?.data?.message}`);
                } else {
                    Toast('e', `${res?.data?.message}`);
                }
            };
            let data = {
                old_password: formVal?.old_password,
                new_password: formVal?.new_password
            };
            let url = `user/change_password`;
            dispatch(getFetchApi({ url, data, apiCallBack }));
        } catch (error) {
            Toast('e', error);
        }
    };
    
    return (
        <>
            <Modal open={passwordModal} footer='' closable={false} width="40%">
                <div className="p-3">
                    <div className="text-end">
                        <img src={closeImg} alt="close" width='6%' onClick={() => { setPasswordModal(false); passForm.resetFields() }} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="row">
                        <div className="col-lg-9 mx-auto modal_font">
                            <div className="text-center">
                                <h5 className='medium_font fw-bold'>Change Password</h5>
                                <p className='mb-5'>Set a new password</p>
                            </div>
                            <Form form={passForm} onFinish={handleChangePassword} autoComplete="off" >
                                <Form.Item
                                    name="old_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your old password!',
                                        },
                                    ]}
                                >
                                    <Input.Password type='password' placeholder='Old Password' className='common_input profile_input'
                                        iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="new_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password type='password' placeholder='New Password' className='common_input profile_input'
                                        iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="c_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input confirm password!',
                                        },
                                    ]}
                                >
                                    <Input.Password type='password' placeholder='Confirm Password' className='common_input profile_input'
                                        iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                    />
                                </Form.Item>

                                <Form.Item className='text-center'>
                                    <Button loading={loading} className='common_btn common_btn_width mt-4' type="ghost" htmlType="submit">CONTINUE</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ChangePassword;
