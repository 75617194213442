import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import Routing from './routes/Routing';
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";

const App = () => {
  ReactGA.initialize('G-2QBV601PY7');
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Glenwood</title>
        <meta name="description" content="Glenwood South Entertainment District" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Glenwood" />
        <meta property="og:description" content="Glenwood South Entertainment District" />
        <meta property="og:image" content="https://d22yojhnjc4na0.cloudfront.net/banner_image/banner_image-1715003887981.webp" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <HelmetProvider>
        <Routing />
        <ToastContainer />
      </HelmetProvider>
    </>
  )
}

export default App;

