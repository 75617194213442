// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLcjQ434pxjyx9qpYFRZ2H5wqfAUUtOKo",
  authDomain: "glenwood-38d5a.firebaseapp.com",
  projectId: "glenwood-38d5a",
  storageBucket: "glenwood-38d5a.appspot.com",
  messagingSenderId: "457084735072",
  appId: "1:457084735072:web:7ea0c0236f5d944917fd05",
  measurementId: "G-2QBV601PY7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const socialAuth = getAuth(app);